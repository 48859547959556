import { useState } from "react";
import React from "react";
import { ThailandTourContestQuery } from "../../prismic/staticQueries/ThailandTourContestQuery/index.js"
import Layout from "../../layout";
import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import CountUp from "react-countup";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import banner_image from "../../images/siteImages/home/thailand-top-bg.webp";
import thailand_bg from "../../images/siteImages/dubai-images/thailand-background-bgg.jpg";
import four_img_1 from "../../images/siteImages/forex/MarketIcons/topshelftrading.svg";
import four_img_2 from "../../images/siteImages/forex/MarketIcons/currency_pairs.svg";
import four_img_3 from "../../images/siteImages/forex/MarketIcons/forex_market_hours.svg";
import four_img_4 from "../../images/siteImages/forex/MarketIcons/veteranexpertise.svg";
import open_acc from "../../images/siteImages/forex/MarketIcons/topshelftrading.svg";
import equity from "../../images/siteImages/forex/MarketIcons/currency_pairs.svg";
import trade_min from "../../images/siteImages/forex/MarketIcons/forex_market_hours.svg";
import win_dubai from "../../images/siteImages/forex/MarketIcons/veteranexpertise.svg";
import thai_1 from "../../images/siteImages/dubai-images/thai-1.jpg";
import thai_2 from "../../images/siteImages/dubai-images/thai-2.jpg";
import thai_3 from "../../images/siteImages/dubai-images/thai-3.jpg";
import thai_4 from "../../images/siteImages/dubai-images/thai-4.jpg";
import thai_5 from "../../images/siteImages/dubai-images/thai-5.jpg";
import thai_6 from "../../images/siteImages/dubai-images/thai-6.jpg";
import dubai_right_image from "../../images/siteImages/dubai-images/thailand-wat-arun.jpg";
import dubai_left_image from "../../images/siteImages/dubai-images/dubai-office.jpeg";
import ThailandTourContestCountDown from "./ThailandTourContestCountDown.js";
import PaymentSlider from "../Company/PaymentSlider.js";

//const targetDate = new Date().getTime() + 5 * 24 * 60 * 60 * 1000;
const targetDate = new Date('2024-09-30T23:59:00').getTime();

const ThailandTourContest = () => {
  const language = useSelector((state) => state.language);
  const ThailandTourContestData = ThailandTourContestQuery(language);
  const [loadAllTerms, setLoadAllTerms] = useState(false);

  console.log("language", ThailandTourContestData);
  const {
    dubai_heading,
    DespositAmt,
    BookBefore,
    OriginalDate,
    ExtendedDate,
    dubai_top_heading,
    dubai_top_heading1,
    dubai_top_description,
    dubai_top_description1,
    dubai_top_description2,
    four_box,
    three_box,
    dubaiimages_box,
    terms_box,
    key_figures_boxes,
  } = ThailandTourContestData;

  const four_icon = [
    open_acc,
    equity,
    trade_min,
    win_dubai
  ];

  const dubaiimages_icon = [
    thai_1,
    thai_2,
    thai_3,
    thai_4,
    thai_5,
    thai_6
  ];

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (

    <Layout >
      <BreadCrumbBanner banner_image={banner_image} />
      <section className="gradient-bg">
        <Container className="">
          <Row className="py-3">
            {three_box.length > 0 && three_box.map((threemap, threemapInd) => (
              <Col className="three-card"
                lg={4}
                md={4} key={"threemapInd" + threemapInd}>
                <div className="h-100 d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="mb-0"> {threemap.three_heading.text}</h4>
                    <p> {threemap.three_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>

      <section className="gradient-bg d-none">
        <Container className="">
          <Row className="py-3">
              <Col lg={12} md={12}>
                <div className="h-100">
                  <div className="d-flex flex-column align-items-center">
                    <h4 className="mb-0"> {DespositAmt.text}</h4>
                    <p> {BookBefore.text} <strike>{OriginalDate.text}</strike></p>
                    <p> {ExtendedDate.text}</p>
                  </div>
                </div>
              </Col>
          </Row>
        </Container>
      </section>
     
     <div className="main-bg" style={{ backgroundImage: `url(${thailand_bg})`}}>
      <section className="">
        <Container className="">
          <Row className="pt-5">
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <h2 className="text-bold-md text-black">{dubai_top_heading.text}</h2>
              <p className="text-gray my-3">
                {dubai_top_description.text}</p>
            </Col>
            <Col lg={1}></Col>
            <Col lg={4} className="py-5 d-flex align-items-center">
              <img alt="ddd" src={dubai_right_image} className="dubai-img" />
            </Col>
          </Row>
          <Row className="pb-5 d-none">
            <Col lg={4} className="py-5 d-flex align-items-center">
              <img alt="ddd" src={dubai_left_image} className="dubai-img" />
            </Col>
            <Col lg={1}></Col>
            <Col lg={6} className="d-flex flex-column justify-content-center">
              <h2 className="text-bold-md text-black">{dubai_top_heading1.text}</h2>
              <strong className="text-bold-md text-black">Forex expo 19-20 May 2024</strong>
              <p className="text-gray my-3">
                {dubai_top_description1.text}</p>
              <p className="text-gray my-3">
                {dubai_top_description2.text}</p>
              <Row className="py-5">
                {key_figures_boxes.map((item, boxInd) => (
                  <Col md={3} sm={12} key={"sdf" + boxInd}>
                    <div className="items-counter-dubai text-center">
                      <b className="value text-blue">
                        <span className="count_up">
                          <CountUp
                            delay={1}
                            end={parseInt(item.number_only.text)}
                            prefix={item.number_prefix.text}
                            suffix={item.number_sufix ? item.number_sufix.text : ""}
                          />
                          {/* {item.key_feature_box_title.text} */}
                        </span>
                      </b>
                      <p className="title text-dark mb-0">
                        {item.key_feature_box_description.text}{" "}
                      </p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <section className=" py-5">
        <Container>
          <Row>
            <Col md="12">
              <ThailandTourContestCountDown  targetDate={targetDate} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="">
        <Container className="">
          <Row className="pb-5">
            <Col lg={12} md={12}>
              {/* <h2 className="my-5 text-bold-md text-black text-center">{dubai_heading.text}</h2> */}
              <h2 className="my-5 text-bold-md text-black text-center">Trade Deposited funds for <br/>180 Days</h2>
            </Col>
            <Col className="mb-5 d-flex justify-content-center"
                lg={3}
                md={6}>
                <div className="four-card mb-4 w-280">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-1">
                    <img alt="ddd" src={four_img_1} className="icon-width m-2" style={{ width: "70px" }} />
                    </div>
                    <p>Open a live Trading Account </p>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 d-flex justify-content-center"
                lg={3}
                md={6}>
                <div className="four-card mb-4 w-280">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-1">
                    <img alt="ddd" src={four_img_2} className="icon-width m-2" style={{ width: "70px" }} />
                    </div>
                    <p>Deposit Fresh Fund<br/>$8K USD </p>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 d-flex justify-content-center"
                lg={3}
                md={6}>
                <div className="four-card mb-4 w-280">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-1">
                    <img alt="ddd" src={four_img_3} className="icon-width m-2" style={{ width: "70px" }} />
                    </div>
                    <p> Trade your deposited <br/>Funds for 180 Days</p>
                  </div>
                </div>
              </Col>
              <Col className="mb-5 d-flex justify-content-center"
                lg={3}
                md={6}>
                <div className="four-card mb-4 w-280">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-1">
                    <img alt="ddd" src={four_img_4} className="icon-width m-2" style={{ width: "70px" }} />
                    </div>
                    <p> Win a Trip for Thailand<br/>(4 Night/5 Days)</p>
                    <p> Entry Pass for an exclusive<br/>GALA DINNER PARTY</p>
                  </div>
                </div>
              </Col>
          </Row>
        </Container>
      </section>
      <section className="d-none">
        <Container className="">
          <Row className="pb-5">
            <Col lg={12} md={12}>
              <h2 className="my-5 text-bold-md text-black text-center">{dubai_heading.text}</h2>
            </Col>
            {four_box.length > 0 && four_box.map((fourmap, fourmapInd) => (
              <Col className="mb-5 d-flex justify-content-center"
                lg={3}
                md={6} key={"fourmapInd" + fourmapInd}>
                <div className="four-card mb-4 w-280">
                  <div className="d-flex flex-column mb-2 align-items-center">
                    <div className="box-icon mb-1">
                      <img alt="ddd" src={four_icon[fourmapInd]} className="icon-width m-2" style={{ width: "70px" }} />
                    </div>
                    <p> {fourmap.four_description.text}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className=" p-top-10 pb-5 p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <h2 className="pt-5 my-3 text-bold-md text-black">Terms and Conditions</h2>
            </Col>
            <Col lg={12}>
              <ul>
                {terms_box.slice(0, loadAllTerms ? terms_box.length : 5).map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}>
                    {reason.DemoTerms_content.text}
                  </li>
                ))}
              </ul>
              {!loadAllTerms && (
                <div className="text-center pt-3">
                  <button
                    className="btn btn-primary p-3" style={{ lineHeight: "0", borderRadius: "50%" }}
                    onClick={() => setLoadAllTerms(true)}
                  >
                    <i class="fa fa-plus-circle" aria-hidden="true" style={{ fontSize: "20px" }}></i>
                  </button>
                </div>
              )}
            </Col>
            {/* <Col lg={12}>
              <ul>
                {terms_box.map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}>
                    {reason.DemoTerms_content.text}
                  </li>
                ))}

              </ul>
            </Col> */}
          </Row>
        </Container>
      </section>
      <section className="">
        <Container className="">
          <Row className="py-5">
            {dubaiimages_box.length > 0 && dubaiimages_box.map((dubaiimagesmap, dubaiimagesmapInd) => (
              <Col md={2} lg={2} sm={2} key={"dubaiimagesInd" + dubaiimagesmapInd}>
                <div class="image bg-yellow">
                  <img class="slider-img" src={dubaiimages_icon[dubaiimagesmapInd]} alt="Rasm" />
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      </div>
      <PaymentSlider/>
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(ThailandTourContest);